import React from "react"
import styled from "styled-components"
import { graphql, useStaticQuery } from "gatsby"
// Components
import Layout from "../components/layout"
import Seo from "../components/seo"
import BigTitle from "../components/titles"
import BlogItem from "../components/blog_item"

const BlogPage = () => {
  const data = useStaticQuery(graphql`
    query MyQuery {
      allContentfulBlog(filter: { node_locale: { eq: "en-US" } }) {
        edges {
          node {
            title
            slug
            banner {
              file {
                url
                fileName
                contentType
              }
            }
          }
        }
      }
    }
  `)
  return (
    <Layout>
      <Seo title="OGD42 - Blog" />
      <div className="container">
        <div
          className="row justify-content-center"
          style={{ paddingTop: "3rem" }}
        >
          <div
            className="col-sm-12 col-md-12 col-lg-8 col-xl-8 d-flex "
            style={{
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <BigTitle>Blog & Media</BigTitle>
            <StyledBlogContainer>
              {data.allContentfulBlog.edges.map((item, key) => {
                return <BlogItem key={`bi${key}`} {...item.node} />
              })}
            </StyledBlogContainer>
          </div>
        </div>
      </div>
    </Layout>
  )
}

const StyledBlogContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
  @media (min-width: 481px) {
    width: 80%;
  }

  @media (min-width: 768px) {
    width: 60%;
  }
`

export default BlogPage
