import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import BigTitle from "../components/titles"
import { SecondaryTheme, PrimaryTheme } from "../constants/theme"

const BlogItem = ({ title, banner, slug }) => (
  <Link to={`/blog_details/${slug}`} style={{ textDecoration: "none" }}>
    <StyledBlogItemContainer backgroundImage={banner.file.url}>
      <BigTitle>{title}</BigTitle>
    </StyledBlogItemContainer>
  </Link>
)

const StyledBlogItemContainer = styled.div`
  background-size: cover;
  background-color: ${PrimaryTheme.text};
  background-attachment: fixed;
  padding: 20px 0 10px;
  margin: 20px 0px 20px 0px;
  border-radius: 10px;
  min-height: 250px;
  display: flex;
  justify-content: center;
  align-items: center;
  h1 {
    font-size: 2.8rem;
    text-align: center;
    text-shadow: 0px 2px 2px rgba(0, 0, 0, 0.2);
    width: 70%;
    color: ${SecondaryTheme.accent};
    transition: border-width 0.3s ease-in-out;
    border-bottom: solid 2px ${SecondaryTheme.primary};
    margin-bottom: 0;
    &:hover {
      border-bottom: solid 6px ${SecondaryTheme.primary};
    }
  }
`

export default BlogItem
