import React from "react"
import styled from "styled-components"
import { SecondaryTheme } from "../constants/theme"

const BigTitle = ({ children, ...rest }) => (
  <StyledTitle {...rest}>{children}</StyledTitle>
)

const StyledTitle = styled.h1`
  color: ${SecondaryTheme.secondary};
  font-size: 3.2rem;
  border-bottom: solid 2px rgba(255, 255, 255, 0.125);
  margin-bottom: 0.8em;
  padding-bottom: 0.4em;
  letter-spacing: 0.2rem;
  @media (max-width: 481px) {
    font-size: 2.2rem;
  }
`

export const Subtitle = ({ children, ...rest }) => (
  <StyledSubtitle {...rest}>{children}</StyledSubtitle>
)

const StyledSubtitle = styled.h2`
  color: ${SecondaryTheme.secondary};
  font-size: 2.4rem;
  letter-spacing: 0.2rem;
`

export default BigTitle
